// @flow
import { ReactElement } from 'react';
import { ToastContainer } from 'react-bootstrap';
import { ToastContainer as ToastifyToastContainer } from 'react-toastify';
import Routes from './routes/Routes';

import 'react-toastify/dist/ReactToastify.css';

import './assets/scss/Saas.scss';

const App = (): ReactElement => {
  return (
    <>
      <Routes />
      <ToastContainer />
      <ToastifyToastContainer />
    </>
  );
};

export default App;
