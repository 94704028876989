/**
 * Configuração das rotas do APP para comunicação com a API
 */

export const getCurrentApiEnv = () => {
  if (process.env.REACT_APP_ENV === 'local') return 'local';
  if (process.env.REACT_APP_ENV === 'homolog') return 'homolog';
  if (process.env.REACT_APP_ENV === 'production') return 'production';
  if (process.env.REACT_APP_ENV === 'NewDevelop') return 'NewDevelop';
  if (process.env.REACT_APP_ENV === 'NewHomolog') return 'NewHomolog';
  return 'dev';
};

export const Environment = {
  baseUrl: {
    local: {
      urlPostalCode: 'https://func-pudo-postalcode-dev.azurewebsites.net/',
      urlOrders: 'https://app-pudo-orders-api-dev.azurewebsites.net/api/',
      urlCarriers: 'https://app-pudo-carriers-api-dev.azurewebsites.net/api/',
      urlPayments: 'https://app-pudo-payments-api-dev.azurewebsites.net/api/',
      urlClients: 'https://app-pudo-clients-api-dev.azurewebsites.net/api/',
      urlBusinessUnit:
        'https://app-pudo-businessunits-api-dev.azurewebsites.net/api/',
      funcSignalR: {
        url: 'https://func-pudo-shared-signalr-dev.azurewebsites.net/api/',
        code: 'DvemEw0mOzSvUqwpdxfNUkTF5jk5iiPnggM01cG-TE-vAzFuI37U3Q==',
      },
      auth0ClientId: 'jUJ18PQ6q7QJ3WiFdaLLa2OVxHL7sHIx',
      auth0Domain: 'pudo-dev.us.auth0.com',
      auth0Audience: 'https://apis/businessunits-api',
    },
    dev: {
      urlPostalCode: 'https://func-pudo-postalcode-dev.azurewebsites.net/',
      urlOrders: 'https://app-pudo-orders-api-dev.azurewebsites.net/api/',
      urlCarriers: 'https://app-pudo-carriers-api-dev.azurewebsites.net/api/',
      urlPayments: 'https://app-pudo-payments-api-dev.azurewebsites.net/api/',
      urlClients: 'https://app-pudo-clients-api-dev.azurewebsites.net/api/',
      urlBusinessUnit:
        'https://app-pudo-businessunits-api-dev.azurewebsites.net/api/',
      funcSignalR: {
        url: 'https://func-pudo-shared-signalr-dev.azurewebsites.net/api/',
        code: 'DvemEw0mOzSvUqwpdxfNUkTF5jk5iiPnggM01cG-TE-vAzFuI37U3Q==',
      },
      auth0ClientId: 'jUJ18PQ6q7QJ3WiFdaLLa2OVxHL7sHIx',
      auth0Domain: 'pudo-dev.us.auth0.com',
      auth0Audience: 'https://apis/businessunits-api',
    },
    NewDevelop: {
      urlPostalCode:
        'https://func-drops-postal-code-dev.azurewebsites.net/api/',
      urlOrders: 'https://app-drops-order-api-dev.azurewebsites.net/api/',
      urlCarriers: 'https://app-drops-carriers-api-dev.azurewebsites.net/api/',
      urlPayments: 'https://app-drops-payment-api-dev.azurewebsites.net/api/',
      urlClients: 'https://app-drops-clients-api-dev.azurewebsites.net/api/',
      urlBusinessUnit:
        'https://app-drops-businessunits-api-dev.azurewebsites.net/api/',
      funcSignalR: {
        url: 'https://func-drops-shared-service-signalr-dev.azurewebsites.net/api/',
        code: 'eSRuFMithnEr1m4Mz0Sll1Hxf5Fz1wS85gt5dCM2JjSxAzFuk1DiGw==',
      },
      auth0ClientId: 'jUJ18PQ6q7QJ3WiFdaLLa2OVxHL7sHIx',
      auth0Domain: 'pudo-dev.us.auth0.com',
      auth0Audience: 'https://apis/businessunits-api',
    },
    homolog: {
      urlPostalCode: 'https://func-pudo-postalcode-hml.azurewebsites.net/api/',
      urlOrders: 'https://app-pudo-orders-api-hml.azurewebsites.net/api/',
      urlCarriers: 'https://app-pudo-carriers-api-hml.azurewebsites.net/api/',
      urlPayments: 'https://app-pudo-payments-api-hml.azurewebsites.net/api/',
      urlClients: 'https://app-pudo-clients-api-hml.azurewebsites.net/api/',
      urlBusinessUnit:
        'https://app-pudo-businessunits-api-hml.azurewebsites.net/api/',
      funcSignalR: {
        url: 'https://func-pudo-shared-signalr-hml.azurewebsites.net/api/',
        code: 'eSRuFMithnEr1m4Mz0Sll1Hxf5Fz1wS85gt5dCM2JjSxAzFuk1DiGw==',
      },
      auth0ClientId: 'h5YfWejzGU4tdK09Ie8TrUrLEl06H7Cr',
      auth0Domain: 'login-hml.pontodrops.com.br',
      auth0Audience: 'https://apis/businessunits-api',
    },
    NewHomolog: {
      urlPostalCode:
        'https://func-drops-postal-code-hml.azurewebsites.net/api/',
      urlOrders: 'https://app-drops-order-api-hml.azurewebsites.net/api/',
      urlCarriers: 'https://app-drops-carriers-api-hml.azurewebsites.net/api/',
      urlPayments: 'https://app-drops-payment-api-hml.azurewebsites.net/api/',
      urlClients: 'https://app-drops-clients-api-hml.azurewebsites.net/api/',
      urlBusinessUnit:
        'https://app-drops-businessunits-api-hml.azurewebsites.net/api/',
      funcSignalR: {
        url: 'https://func-drops-shared-service-signalr-hml.azurewebsites.net/api/',
        code: 'eSRuFMithnEr1m4Mz0Sll1Hxf5Fz1wS85gt5dCM2JjSxAzFuk1DiGw==',
      },
      auth0ClientId: 'h5YfWejzGU4tdK09Ie8TrUrLEl06H7Cr',
      auth0Domain: 'login-hml.pontodrops.com.br',
      auth0Audience: 'https://apis/businessunits-api',
    },
    production: {
      urlPostalCode: 'https://postalcode.pontodrops.com.br/api/',
      urlOrders: 'https://orders-api.pontodrops.com.br/api/',
      urlCarriers: 'https://carriers-api.pontodrops.com.br/api/',
      urlPayments: 'https://payments-api.pontodrops.com.br/api/',
      urlClients: 'https://clients-api.pontodrops.com.br/api/',
      urlBusinessUnit: 'https://businessunits-api.pontodrops.com.br/api/',
      funcSignalR: {
        url: 'https://signalr.pontodrops.com.br/api/',
        code: 't2fgh6TJCAVkE_bzNc5gwpOZgatjhTku2DAUe43HRqOZAzFuxOZ7bQ==',
      },
      auth0ClientId: '0pLhh2QTHgrmC9GDQLPxFxQ81uNYFiKd',
      auth0Domain: 'login.pontodrops.com.br',
      auth0Audience: 'https://apis/businessunits-api',
    },
  },
};
