import React from 'react';
import { Redirect } from 'react-router-dom';
import { Route } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';

// auth
const Logout = React.lazy(() => import('../pages/account/Logout'));

// pages
// const PanelOperation = React.lazy(() =>
//   import('../pages/panelOperation')
// );
// const PanelOperationDetails = React.lazy(() =>
// import('../pages/panelOperation/details')
// );
// const Financial = React.lazy(() =>
//   import('../pages/financial')
// );
// const ClientFirstAccess = React.lazy(() =>
//   import('../pages/pudo/firstAccess')
// );
const CheckFirstAccess = React.lazy(() =>
  import('../pages/pudo/checkFirstAccess')
);
const Packages = React.lazy(() =>
  import('../pages/packages')
);
const ManageOrders = React.lazy(() =>
  import('../pages/manageOrders')
);
const EndCostumerDetails = React.lazy(() =>
  import('../pages/packages/endCostumerDetails')
);
const ErrorPageNotFound = React.lazy(() =>
  import('../pages/error/PageNotFound')
);
// const MultiplePackages = React.lazy(() =>
//   import('../pages/packages/components/MultiplePackages')
// );

const ServerError = React.lazy(() => import('../pages/error/ServerError'));
const RedirectApp = React.lazy(() => import('../pages/account/RedirectApp'));
// const LabelPage = React.lazy(() => import('../pages/panelOperation/LabelPage/LabelPage'));

// root routes
const rootRoute = {
  path: '/',
  exact: true,
  name: 'Home',
  component: () => <Redirect to="/pudo/check-access" />,
  route: PrivateRoute,
};

// dashboards
const dashboardRoutes = {
  path: '/dashboard',
  name: 'Dashboards',
  icon: 'uil-home-alt',
  header: 'Navigation',
  children: [
    {
      path: '/packages',
      name: 'packages',
      badge: {
        variant: 'success',
        text: '3',
      },
      component: Packages,
      route: PrivateRoute,
      exact: true,
    },
    {
      path: '/package-end-costumer-details/:id',
      name: 'packages',
      badge: {
        variant: 'success',
        text: '3',
      },
      component: EndCostumerDetails,
      route: PrivateRoute,
      exact: true,
    },
    {
      path: '/package-end-costumer-details-document/:code',
      name: 'packages',
      badge: {
        variant: 'success',
        text: '3',
      },
      component: EndCostumerDetails,
      route: PrivateRoute,
      exact: true,
    },
    // {
    //   path: '/packages/multiple-packages',
    //   name: 'packages',
    //   badge: {
    //     variant: 'success',
    //     text: '3',
    //   },
    //   component: MultiplePackages,
    //   route: PrivateRoute,
    //   exact: true,
    // },
    // {
    //   path: '/panel-operation',
    //   name: 'PanelOperation',
    //   badge: {
    //     variant: 'success',
    //     text: '3',
    //   },
    //   component: PanelOperation,
    //   route: PrivateRoute,
    //   exact: true
    // },
    {
      path: '/manage-orders',
      name: 'manageOrders',
      badge: {
        variant: 'success',
        text: '3',
      },
      component: ManageOrders,
      route: PrivateRoute,
      exact: true
    },
    // {
    //   path: '/panel-operation/details/:id',
    //   name: 'PanelOperationDetails',
    //   badge: {
    //     variant: 'success',
    //     text: '3',
    //   },
    //   component: PanelOperationDetails,
    //   route: PrivateRoute,
    // },
  ],
};
// const financialRoutes = {
//   path: '/financial',
//   name: 'Financeiro',
//   component: Financial,
//   icon: 'uil-bill',
//   header: 'Navigation',
//   route: Route,
// };

const otherPublicRoutes = [
  {
    path: '/error-404',
    name: 'Error - 404',
    component: ErrorPageNotFound,
    route: Route,
  },
  {
    path: '/error-500',
    name: 'Error - 500',
    component: ServerError,
    route: Route,
  },
  {
    path: '/redirect-app',
    name: 'RedirectApp',
    component: RedirectApp,
    route: Route,
  },
];

// flatten the list of all nested routes
const flattenRoutes = (routes) => {
  let flatRoutes = [];

  routes = routes || [];
  routes.forEach((item) => {
    flatRoutes.push(item);

    if (typeof item.children !== 'undefined') {
      flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
    }
  });
  return flatRoutes;
};

// auth
const authRoutes = [
  {
    path: '/account/logout',
    name: 'Logout',
    component: Logout,
    route: Route,
  },
];

const routesDefaultLayout = [
  // {
  //   path: '/pudo/registration',
  //   name: 'Registration',
  //   component: ClientFirstAccess,
  //   route: PrivateRoute,
  // },
  {
    path: '/pudo/check-access',
    name: 'Init',
    component: CheckFirstAccess,
    route: PrivateRoute,
  },
];

// const defaultLabelRoute = [
//   {
//     path: '/LabelPage/:tracking_number',
//     name: 'LabelPage',
//     component: LabelPage,
//     route: PrivateRoute,
//   },
// ];

// All routes
const authProtectedRoutes = [dashboardRoutes];
const publicRoutes = [
  rootRoute,
  ...authRoutes,
  ...otherPublicRoutes,
  ...routesDefaultLayout,
  // ...defaultLabelRoute,
];

const authProtectedFlattenRoutes = flattenRoutes([...authProtectedRoutes]);
const publicProtectedFlattenRoutes = flattenRoutes([...publicRoutes]);

export { authProtectedFlattenRoutes, publicProtectedFlattenRoutes };
